body {
  background-color: #0A0A0E;
}

.bgPrimaryGradient {
  background-image: linear-gradient(to right, #FF9800, #C97629);
}

.bgPrimaryGradientVertical {
  background-image: linear-gradient(to bottom, #FF9800, #C97629);
}

.sidebar-item {
  display: grid;
  grid-template-columns: auto 30px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #E9E9E9;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #e8e8e8;
  border-radius: 5px;
}

/* CKEditor */
.ck.ck-toolbar {
  background-color: transparent !important;
  border-top: 1px solid #FF9800 !important;
  border-left: 1px solid #FF9800 !important;
  border-right: 1px solid #FF9800 !important;
  border-bottom: 0 !important;
}

.ck.ck-reset_all, .ck.ck-reset_all * {
  color: gray !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: black !important;
  border-bottom: 1px solid #FF9800 !important;
  border-left: 1px solid #FF9800 !important;
  border-right: 1px solid #FF9800 !important;
  border-top: 0;
  min-height: 170px !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
input:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* oldIE ;) */

input::placeholder {
  padding-left: 15px !important;
}

input.primary-placeholder::placeholder {
  color: #FF9800 !important;
}

.gray-placeholder {
  &::placeholder {
  color: #717579 !important;
  font-size: 16px !important;
  position: relative;
  top: 3px !important;
  @media(min-width: 640px) {
    font-size: 24px !important;
    margin-top: 6px !important;
  }
 }
}

.my-contents.open{
  @media (min-width: 1024px) {
   padding-left: 250px;
  }
  @media (min-width: 1440px) {
   padding-left: 270px;
  }
  @media (min-width: 1600px) {
   padding-left: 360px;
  }
}
.my-contents{
  @media (min-width: 1024px) {
   padding: 30px;
  }
}

.Toastify__toast-container {
  width: 90%;
}

//custom datepicker
.custom-datepicker {
  width: 295px;
  height: 60px;
  border-radius: 50px;
  background-color: #FF9800;
  padding: 20px 20px 6px 130px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  color: transparent;
}

.react-datepicker {
  background-color: #0A0A0E !important;
  border-radius: 10px !important;
  border: none !important;
  color: #fff !important;
  padding: 0 24px !important;
  &__triangle {
    display: none !important;
  }
  &__header {
  background-color: #0A0A0E !important;
  border: none !important;

  }
  &__current-month {
    color: #fff !important;
  }
  &__day-name {
    color: #fff !important;
  }
  &__navigation {
    top: 8px !important;
  }
  &__navigation--previous {
    left: 40px !important;
  }
  &__navigation--next {
    right: 40px !important;
  }
  &__navigation-icon {
    &::before {
     border-color: #87C289 !important;

    }
  }
  &__day {
    color: #fff !important;
    width: 30px !important;
    height: 30px !important;
    &--range-start, &--range-end {
      border-radius: 50% !important;
      color: #A9D3AB !important;
      background-color: #132C14 !important;
      border: 2px solid #2B662E !important;
    }
    &--in-selecting-range {
      border-radius: 50% !important;
      color: #A9D3AB !important;
     background-color: #132C14 !important;
      border: 2px solid transparent;
    }
    &--disabled {
     color: #3C3844 !important;
    }
  }
}



