@font-face {
    font-family: 'Avenir';
    src: url('../fonts/AvenirNextLTPro-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/AvenirNextLTPro-Bold.otf') format('otf');
    font-weight: bold;
    font-style: normal;
}

html{
    font-size: 16px;
}

@media only screen and (max-width: 1599px){
    html{
        font-size: 15.5px;
    }
}
@media only screen and (max-width: 1439px){
    html{
        font-size: 14px;
    }
}
@media only screen and (max-width: 650px){
    html{
        font-size: 10px;
    }
}